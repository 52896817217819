function resizeWindow(){

}
function scrollDown(){

}

$(window).bind("load", function () {
	$(document).ready(function () {

		//Call function with browser is resized.
        $(window).resize(function (event) {
             resizeWindow();
        });
        resizeWindow();

        //Acts when scroll down page.
        $(window).scroll(function (event) {
        	scrollDown();
			var top = $(document).scrollTop();
			if (top > 0) {

			} else {

			}
		});


		//Home page slider
		if($("#background").length > 0) {
    		$("#background").cycle({
			    fx:     'fade',
			    speed:  800,
			    timeout: 6000

			});
		}

		// Main Nav Dropdown
	    $("#primary-nav li").hover(function(){

	        $(this).addClass("hover");
	        $('ul.submenu',this).fadeIn(200);

	    }, function(){

	        $(this).removeClass("hover");
	        $('ul.submenu',this).fadeOut(200);

	    });

		//Home page scroll down arrow. If present.
		$('.arrow-home').click(function(event) {
		    $('body, html').animate({
		        scrollTop: $(window).height()-$('header').outerHeight()},
		        800, 'easeOutExpo');

		});

		/*
		This function adds a jquery cycle slider if a accommodations type has more than 1 photo
		set room slideshow, true for zoom*/
		setRoomSlides(true);

		//Event Tracking
        $('.book-now').on('click', function () {
             ga('send', 'event', 'Reservations', 'click', 'Book Now');
        });
        $('#bookNow').on('click', function () {
             ga('send', 'event', 'Header Widget', 'click', 'Book Now');
        });

		//Sample of carousel slider. Include the file is required.
        if ($("#carousel").length > 0) {
             $('#carousel').bxSlider({
                 slideWidth: 313,
                 minSlides: 3,
                 maxSlides: 3,
                 slideMargin: 0,
                 responsive: true,
                 pager: false
             });
        }

		//Check in and check out datepicker for booking engine
		$( "#CheckIn" ).datepicker({
			showOn: "both",
			 buttonImage: SITE_FILES+"images/img-calendar-gray.png",
			buttonImageOnly: true,
			changeMonth: true,
			numberOfMonths: 2,
			minDate: 0,
			onSelect: function( selectedDate ) {
				getDate = $(this).datepicker('getDate');
            	newDate = new Date(getDate.getTime());
            	newDate.setDate(newDate.getDate() + 3);
				$( "#CheckOut" ).datepicker( "setDate", newDate );
				$( "#CheckOut" ).datepicker( "option", "minDate", selectedDate );
			}
		});
		$( "#CheckOut" ).datepicker({
			showOn: "both",
			 buttonImage: SITE_FILES+"images/img-calendar-gray.png",
			buttonImageOnly: true,
			changeMonth: true,
			numberOfMonths: 2,
			onSelect: function( selectedDate ) {
				$( "#CheckIn" ).datepicker( "option", "maxDate", selectedDate );
			}
		});
     });
});